import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import { DepartmentStore } from "./departmentStore";
import ModalStore from "./modalStore";
import { RoutingSlipStore } from "./routingSlipStore";
import { RoutingSlipLogStore } from "./routingSlipLogStore";
import UserStore from "./userStore";
import WorkerStore from "./workerStore";
import RoutingSlipRealtimeStore from "./routingSlipRealtimeStore";
import { PacketStore } from "./packetStore";

interface Store {
    workerStore: WorkerStore,
    departmentStore: DepartmentStore,
    routingSlipLogStore: RoutingSlipLogStore,
    routingSlipRealtimeStore: RoutingSlipRealtimeStore,
    commonStore: CommonStore,
    userStore: UserStore,
    modalStore: ModalStore,
    routingSlipStore: RoutingSlipStore,
    packetStore: PacketStore
}

export const store: Store = {
    workerStore: new WorkerStore(),
    departmentStore: new DepartmentStore(),
    routingSlipLogStore: new RoutingSlipLogStore(),
    routingSlipRealtimeStore: new RoutingSlipRealtimeStore(),
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    routingSlipStore: new RoutingSlipStore(),
    packetStore: new PacketStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}