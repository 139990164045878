import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Header, Segment } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { Link, useHistory, useParams } from "react-router-dom";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { Department } from "../../../app/models/department";

export default observer(function WorkerForm() {
    const { departmentStore } = useStore();
    const { loading, loadDepartment, createDepartment, updateDepartment } = departmentStore;
    const { id } = useParams<{id: string}>();
    const history = useHistory();

    const [department, setDepartment] = useState<Department>({
        id: '',
        name: ''
    });

    useEffect(() => {
        if (id) loadDepartment(id).then(w => setDepartment(w!));
    },[id, loadDepartment])

    const validationSchema = Yup.object({
        name: Yup.string().required('Der Name ist erforderlich')
    });

    const handleFormSubmit = (department: Department) => {
        if(department.id.length === 0) {
            createDepartment(department).then(() => history.push('/departments'));
        } else {
            updateDepartment(department).then(() => history.push('/departments'));
        }
    }

    if(loading) return <LoadingComponent content="Lade Abteilung..." />

    return (
        <Segment clearing>   
            <Header content='Abteilung' color='teal' />
            <Formik 
                enableReinitialize 
                initialValues={department} 
                validationSchema={validationSchema} 
                onSubmit={values => handleFormSubmit(values)}>
                {({handleSubmit, isValid, isSubmitting, dirty}) => (
                    <Form className='ui form' onSubmit={handleSubmit} autoComplete="off">
                        <MyTextInput name='name' placeholder="Name" />
                        <Button disabled={isSubmitting || !dirty || !isValid} loading={loading} floated="right" positive type='submit' content='Speichern' />
                        <Button as={Link} to='/departments' floated="right" type='button' content='Abbrechen' />
                    </Form>
                )}
            </Formik>
        </Segment>
    )
});