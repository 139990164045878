import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Segment, Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { formatDistanceToNow } from 'date-fns';
import { de } from 'date-fns/locale';

export default observer(function RoutingSlipLogTable() {
    const { routingSlipRealtimeStore } = useStore();
    const { routingSlips } = routingSlipRealtimeStore;

    useEffect(() => {
        routingSlipRealtimeStore.createHubConnection();

        return () => {
            routingSlipRealtimeStore.clearLogs();
        }
    }, [routingSlipRealtimeStore])

    return (
        <Segment>
        <Table compact selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Zeit</Table.HeaderCell>
                    <Table.HeaderCell>Barcode</Table.HeaderCell>
                    <Table.HeaderCell>Nachricht</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {routingSlips.map(log => (
                    <Table.Row key={log.id}>
                        <Table.Cell>vor {formatDistanceToNow(log.date, {locale: de})}</Table.Cell>                         
                        <Table.Cell>{log.orderId}</Table.Cell>  
                        <Table.Cell>{log.message}</Table.Cell>  
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    </Segment>
    )
})