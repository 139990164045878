import { makeAutoObservable, runInAction } from "mobx";
import { history } from "../..";
import agent from "../api/agent";
import { CurrentUser, LoginUser, User, UserChangePassword } from "../models/user";
import { store } from "./store";
import { v4 as uuid } from 'uuid';

interface UserOptions {
    key: string;
    value: string;
    text: string;
}

export default class UserStore {
    userRegistry = new Map<string, User>();
    userOptions: UserOptions[] = [];
    user: CurrentUser | null = null;
    loading = false;
    loadingInitial = false;
    refreshTokenTimeout: any;

    constructor() {
        makeAutoObservable(this);
    }

    get users() {
        return Array.from(this.userRegistry.values());
    }

    get isLoggedId() {
        return !!this.user;
    }

    login = async (creds: LoginUser) => {
        try {
            const user = await agent.Account.login(creds);
            store.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user);
            runInAction(() => this.user = user);
            history.push('/routingslip');
            store.modalStore.closeModal();
        } catch(error) {
            throw error;
        }
    }

    logout = () => {
        store.commonStore.setToken(null);
        this.user = null;
        history.push('/');
    }

    loadUsers = async () => {
        this.loadingInitial = true;
        try {
            const result = await agent.Users.list();
            runInAction(() => {
                this.userRegistry.clear();
                this.userOptions = [];
                result.forEach(user => {
                    this.userRegistry.set(user.id, user);
                    this.userOptions.push({
                        key: user.id,
                        value: user.id,
                        text: user.displayName
                    });
                });
                this.loadingInitial = false;
            })
        } catch(error) {
            console.log(error);
            runInAction(() => this.loadingInitial = false);
        }
    }

    loadUser = async (id: string) => {
        let user = this.getUser(id);
        if(user) {
            return user;
        }
        else {
            this.loadingInitial = true;
            try {
                user = await agent.Users.details(id);
                runInAction(() => {
                    this.userRegistry.set(user!.id, user!);
                    this.loadingInitial = false;
                });
                return user;
            } catch(error) {
                console.log(error);
                runInAction(() => this.loadingInitial = false);
            }
        }
    }

    private getUser = (id: string) => {
        return this.userRegistry.get(id);
    }

    createUser = async (user: User) => {
        this.loading = true;
        user.id = uuid();
        try {
            await agent.Users.create(user);
            runInAction(() => {
                this.userRegistry.set(user.id, user);
                this.loading = false;
            });
        } catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    getCurrentUser = async () => {
        try {
            const user = await agent.Account.current();
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            this.startRefreshTokenTimer(user);
        } catch(error) {
            console.log(error);
        }
    }

    updateUser = async(user: User) => {
        this.loading = true;
        try {
            await agent.Users.update(user);
            runInAction(() => {
                this.userRegistry.set(user.id, user);
                this.loading = false;
            })
        } catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    deleteUser = async (id: string) => {
        this.loading = true;
        try {
            await agent.Users.delete(id);
            runInAction(() => {
                this.userRegistry.delete(id);
                this.loading = false;
            })
        } catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
        }
    }

    refreshToken = async () => {
        this.stopRefreshTokenTimer();
        try{
            const user = await agent.Account.refreshToken();
            runInAction(() => this.user = user);
            store.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user);
        } catch(error) {
            console.log(error);
        }
    }

    changePassword = async (passwords: UserChangePassword) => {
        try {
            this.loading = true;
            await agent.Account.changePassword(passwords);
            this.loading = false;
        } catch(error) {
            this.loading = false;
            throw error;
        }
    }

    private startRefreshTokenTimer(user: CurrentUser) {
        const jwtToken = JSON.parse(atob(user.token.split('.')[1]));
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (300 * 1000);
        this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}