import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Pagination } from "../models/pagination";
import { RoutingSlipLog } from "../models/routingSlipLog";

export class RoutingSlipLogStore {
    routingSlipLogRegistry = new Map<string, RoutingSlipLog>();
    csvFile: Blob | null = null;
    selectedWorker: string | null = null;
    selectedDepartment: string | null = null;
    searchField: string | null = null;
    pagination: Pagination | null = null;
    pageNumber: number = 1;
    pageSize: number = 15;
    sortedColumn: string | null = null;
    sortedDirection: "ascending" | "descending" | undefined;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pageNumber.toString());
        params.append('pageSize', this.pageSize.toString());
        return params;
    }

    get routingSlipLogs() {
        return Array.from(this.routingSlipLogRegistry.values());
    }

    handleSort = async (column: string) => {
        if(column === this.sortedColumn) {
            this.sortedDirection = this.sortedDirection === 'ascending' ? 'descending' : 'ascending';
        }
        else {
            this.sortedDirection = 'ascending';
        }
        this.sortedColumn = column;

        await this.loadRoutingSlipLogs();
    }

    loadCsvFile = async () =>  {
        this.loading = true;
        try {
            const params = this.axiosParams;

            if(this.selectedWorker) {
                params.append("workerId", this.selectedWorker);
            }
            if(this.selectedDepartment) {
                params.append("departmentId", this.selectedDepartment);
            }
            if(this.searchField) {
                params.append("searchBy", this.searchField);
            }
            if(this.sortedColumn) {
                params.append("sortBy", this.sortedColumn);
            }
            if(this.sortedDirection) {
                params.append("sortOrder", this.sortedDirection);
            }

            const result = await agent.RoutingSlipLogs.csv(params);

            runInAction(() => {
                this.setCsvFile(result);
                this.loading = false;
            })
        }
        catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
        }
    }

    loadRoutingSlipLogs = async (workerId: string = "") => {
        this.loading = true;
        try {

            const params = this.axiosParams;

            if(this.selectedWorker) {
                params.append("workerId", this.selectedWorker);
            }
            if(this.selectedDepartment) {
                params.append("departmentId", this.selectedDepartment);
            }
            if(this.searchField) {
                params.append("searchBy", this.searchField);
            }
            if(this.sortedColumn) {
                params.append("sortBy", this.sortedColumn);
            }
            if(this.sortedDirection) {
                params.append("sortOrder", this.sortedDirection);
            }

            const result = await agent.RoutingSlipLogs.list(params);

            runInAction(() => {
                this.routingSlipLogRegistry.clear();
                result.data.forEach(log => {
                    log.date = new Date(log.date);
                    this.routingSlipLogRegistry.set(log.id, log);
                })
                this.setPagination(result.pagination);
                this.loading = false;
            })
        }
        catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
        }
    }

    setCsvFile = (data: Blob | null) => {
        this.csvFile = data;
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setSelectedWorker = (workerId: string) => {
        this.selectedWorker = workerId;
    }

    setSelectedDepartment = (departmentId: string) => {
        this.selectedDepartment = departmentId;
    }

    setSearchField = (searchField: string) => {
        this.searchField = searchField;
    }

    setPageNumber = (pageNumber: number) => {
        this.pageNumber = pageNumber;
    }

    setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    }
}