import { observer } from "mobx-react-lite";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Icon, Segment, Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import DeleteWorker from "./DeleteWorker";

export default observer(function WorkerTable() {
    const { workerStore, modalStore } = useStore();
    const { workers } = workerStore;
    const history = useHistory();

    const handleEdit = (id: string) => {
        history.push(`/workers/${id}`);
    }

    const handleDelete = (id: string, name: string) => {
        modalStore.openModal(<DeleteWorker id={id} name={name} />, 'tiny');
    }

    return (
        <Segment>
            <Table compact selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Pers.-Nr.</Table.HeaderCell>
                        <Table.HeaderCell>Abteilung</Table.HeaderCell>
                        <Table.HeaderCell singleLine title="Beim Laufzettel scannen anzeigen?">Anzeigen</Table.HeaderCell>
                        <Table.HeaderCell>
                            <Button
                                floated="right"
                                icon
                                labelPosition="left"
                                color='blue'
                                size="small"
                                as={Link}
                                to='/createWorker'
                            >
                                <Icon name='user' /> Neu
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {workers.map(worker => (
                        <Table.Row key={worker.id}>
                            <Table.Cell>{worker.name}</Table.Cell>
                            <Table.Cell>{worker.number}</Table.Cell>
                            <Table.Cell>{worker.departmentName}</Table.Cell> 
                            <Table.Cell collapsing textAlign="center">{worker.active ? (<Icon name="check circle" color="green" /> ) : (<Icon name="times circle" color="red" />)}</Table.Cell>                           
                            <Table.Cell collapsing>
                                <div style={{float: 'right'}}>
                                    <Icon onClick={() => handleDelete(worker.id, worker.name)} title="Löschen" name='trash alternate' color="red" style={{marginRight: '20px',cursor: 'pointer'}} />
                                    <Icon onClick={() => handleEdit(worker.id)} title="Bearbeiten" name='pencil alternate' style={{cursor: 'pointer'}} />
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Segment>
    )
});