import React, { SyntheticEvent } from "react";
import { Button, Dropdown, Form, Icon, Input } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";

export default function RoutingSlipLogSearchBars() {
    const { workerStore, departmentStore, routingSlipLogStore } = useStore();
    const { workerOptions } = workerStore;
    const { departmentOptions } = departmentStore;
    const { loadRoutingSlipLogs, setSelectedWorker, setSelectedDepartment, setSearchField } = routingSlipLogStore;

    const onWorkerChanged = (event: SyntheticEvent, data: any) => {
        setSelectedWorker(data.value);
        loadRoutingSlipLogs();
    }

    const onDepartmentChanged = (event: SyntheticEvent, data: any) => {
        setSelectedDepartment(data.value);
        loadRoutingSlipLogs();
    }

    const onFormSubmit = () => {
        loadRoutingSlipLogs();
    }

    return (
            <Form autoComplete="off" onSubmit={onFormSubmit}>
                <Dropdown 
                    search
                    selection
                    clearable
                    placeholder="Mitarbeiter"
                    options={workerOptions}
                    onChange={onWorkerChanged}
                />
                <Dropdown 
                    search
                    selection
                    clearable
                    placeholder="Abteilung"
                    options={departmentOptions}
                    onChange={onDepartmentChanged}
                />
                <Input 
                    placeholder="Barcode"
                    onChange={(e) => setSearchField(e.target.value)}
                    action>
                        <input />
                        <Button type='submit' primary>
                            <Icon name='search'/>
                        </Button>
                </Input>
            </Form>
    );
}