import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Grid, Pagination, PaginationProps } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { PagingParams } from "../../../app/models/pagination";
import { useStore } from "../../../app/stores/store";
import WorkerTable from "./WorkerTable";

export default observer(function WorkerDashboard() {
    const {workerStore} = useStore();
    const {loadWorkers, pagination, setPagingParams} = workerStore;

    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
      loadWorkers();
    },[loadWorkers])

    function handlePageLoading(props: PaginationProps) {
        setPageLoading(true);
        setPagingParams(new PagingParams(+props.activePage!))
        loadWorkers().then(() => setPageLoading(false));
    }
  
    if(workerStore.loadingInitial && !pageLoading) return <LoadingComponent content='Lade Mitarbeiter...' />

    return (
        <Grid>
            <Grid.Column width='16'>
                <WorkerTable />
                <Pagination 
                    style={{float: 'right'}}
                    disabled={pageLoading}
                    defaultActivePage={pagination?.currentPage}
                    totalPages={pagination ? pagination.totalPages : 0}
                    onPageChange={(e, d) => handlePageLoading(d)}
                    boundaryRange={0}
                    siblingRange={1}
                    ellipsisItem={null}
                 />
            </Grid.Column>
        </Grid>
    )
});