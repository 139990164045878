import React from "react";
import { Message } from "semantic-ui-react";

interface Props {
    errors: string[];
}

export default function FormErrorList({errors}: Props) {
    return (
        <Message error>
            <Message.List>
                {errors.map((error: string, i) => 
                    <Message.Item key={i}>{error}</Message.Item>
                )}
            </Message.List>
        </Message>
    )
}