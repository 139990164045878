import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Grid } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import UserTable from "./UserTable";

export default observer(function UserDaschboard() {
    const {userStore} = useStore();
    const {loadUsers} = userStore;

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    if(userStore.loadingInitial) return <LoadingComponent content='Lade Benutzer...' />

    return (
        <Grid>
            <Grid.Column width='16'>
                <UserTable />
            </Grid.Column>
        </Grid>
    )
})