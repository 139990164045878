import React, { useEffect, useState } from "react";
import FileSaver from "file-saver";
import { observer } from "mobx-react-lite";
import { Button, Grid, Pagination, PaginationProps } from "semantic-ui-react";
import PageCount from "../../app/common/components/PageCount";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import PacketSearchBar from "./PacketsSearchBar";
import PacketTable from "./PacketsTable";

export default observer(function PacketsDashboard() {
    const { packetStore } = useStore();
    const { loadPackets, loading, pagination, setPageNumber, setPageSize, csvFile, setCsvFile, loadCsvFile } = packetStore;

    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        loadPackets();
    }, [loadPackets])

    useEffect(() =>  {
        if(csvFile !== null) {
            FileSaver.saveAs(csvFile!, "pakete.csv"); 
            setCsvFile(null);
        }
    }, [csvFile, setCsvFile])

    const handlePageLoading = (props: PaginationProps) => {
        setPageLoading(true);
        setPageNumber(+props.activePage!);
        loadPackets().then(() => setPageLoading(false));
    }

    const handlePageCountChanged = (pageSize: number) => {
        setPageLoading(true);
        setPageNumber(1);
        setPageSize(pageSize);
        loadPackets().then(() => setPageLoading(false));
    }

    const handleDownloadCSV = () => {
        setPageLoading(true);        
        loadCsvFile().then(() => setPageLoading(false));        
    }

    return(
        <Grid>
            <Grid.Row>
                <Grid.Column width={8}>
                    <PacketSearchBar />                    
                </Grid.Column>
                <Grid.Column width={8}>
                    <Button onClick={handleDownloadCSV} style={{float: 'right'}} primary>Download CSV</Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>     
                    { (loading) && <LoadingComponent content='Lade Pakete...' /> }         
                    <PacketTable />
                    <PageCount onChange={handlePageCountChanged} />
                    <Pagination 
                        style={{float: 'right'}}
                        disabled={pageLoading}
                        activePage={pagination?.currentPage}
                        defaultActivePage={pagination?.currentPage}
                        totalPages={pagination ? pagination.totalPages : 0}
                        onPageChange={(e, d) => handlePageLoading(d)}
                        boundaryRange={0}
                        siblingRange={1}
                        ellipsisItem={null}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
})