import React, { SyntheticEvent } from 'react';
import { useField } from 'formik';
import { CheckboxProps, Form, Radio } from "semantic-ui-react";

interface Props {
    name: string;
    label?: string;
}

export default function MyRadioToggle(props: Props) {
    const [field, meta, helpers] = useField(props.name);

    const onChange = (event: SyntheticEvent<HTMLInputElement, Event>, d: CheckboxProps) => {
        helpers.setValue(d.checked);
    }

    return (
        <Form.Field error={meta.touched && !!meta.error}>
            <Radio
                toggle
                checked={field.value} 
                onChange={onChange}
                label={props.label} 
            />
        </Form.Field>
    )
}