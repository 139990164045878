import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Header, Segment } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { Link, useHistory, useParams } from "react-router-dom";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { Worker } from "../../../app/models/worker";
import MyRadioToggle from "../../../app/common/form/MyRadioToggle";
import MySelectInput from "../../../app/common/form/MySelectInput";

export default observer(function WorkerForm() {
    const { workerStore, departmentStore, userStore } = useStore();
    const { loading: loadingUsers, loadUsers, userOptions } = userStore;
    const { loading, loadingInitial, createWorker, updateWorker, loadWorker } = workerStore;
    const { loading: loadingDepartments, loadOptions, departmentOptions } = departmentStore;
    const { id } = useParams<{id: string}>();
    const history = useHistory();

    const [worker, setWorker] = useState<Worker>({
        id: '',
        name: '',
        number: '',
        message: '',
        active: true,
        departmentId: null,
        signUpUserId: null
    });

    useEffect(() => {
        if (id) loadWorker(id).then(w => setWorker(w!));
        loadOptions();
        loadUsers();
    },[id, loadWorker, loadOptions, loadUsers])

    const validationSchema = Yup.object({
        name: Yup.string().required('Der Name ist erforderlich').max(50, 'Der Name darf max. 50 Zeichen lang sein'),
        number: Yup.string().required('Die Personal-Nr. ist erforderlich').max(10, 'Die Personalnr. darf max. 10 Zeichen lang sein'),
        message: Yup.string().max(50, 'Die Nachricht draf max. 50 Zeichen lang sein'),
        departmentId: Yup.string().required('Bitte eine Abteilung auswählen').nullable(),
        signUpUserId: Yup.string().required('Bitte einen Benutzer auswählen').nullable(),
    });

    const handleFormSubmit = (worker: Worker) => {
        if(worker.id.length === 0) {
            createWorker(worker).then(() => history.push('/workers'));
        } else {
            updateWorker(worker).then(() => history.push('/workers'));
        }
    }

    if(loadingInitial) return <LoadingComponent content="Lade Mitarbeiter..." />

    return (
        <Segment clearing>   
            <Header content='Mitarbeiter' color='teal' />
            <Formik 
                enableReinitialize 
                initialValues={worker} 
                validationSchema={validationSchema} 
                onSubmit={values => handleFormSubmit(values)}>
                {({handleSubmit, isValid, isSubmitting, dirty}) => (
                    <Form className='ui form' onSubmit={handleSubmit} autoComplete="off">
                        <MyTextInput name='name' placeholder="Name" />
                        <MyTextInput name='number' placeholder="Personal-Nr." />
                        <MyTextInput name='message' placeholder="Nachricht" />  
                        <MySelectInput search loading={loadingDepartments} name='departmentId' placeholder="Abteilung" options={departmentOptions} />
                        <MySelectInput search loading={loadingUsers} name='signUpUserId' placeholder="Zugeordnete Benutzer" options={userOptions} />
                        <MyRadioToggle name='active' label="Beim Laufzettel scannen anzeigen?" />
                        <Button disabled={isSubmitting || !dirty || !isValid} loading={loading} floated="right" positive type='submit' content='Speichern' />
                        <Button as={Link} to='/workers' floated="right" type='button' content='Abbrechen' />
                    </Form>
                )}
            </Formik>
        </Segment>
    )
});