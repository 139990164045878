import { useField } from "formik";
import React, { SyntheticEvent } from "react";
import { DropdownProps, Form, Label, Select } from "semantic-ui-react";

interface Props {
    placeholder: string;
    name: string;
    options: any;
    loading?: boolean;
    label?: string;
    search?: boolean;
    onInputChange?: (value: string | number | boolean | undefined | (string | number | boolean)[]) => void;
}

const MySelectInput = (props: Props) => {
    const [field, meta, helpers] = useField(props.name);

    const onChange = (event: SyntheticEvent<HTMLElement, Event>, d: DropdownProps) => {
        helpers.setValue(d.value);
        if(props.onInputChange) props.onInputChange(d.value);
    }

    return (
        <Form.Field error={meta.touched && !!meta.error}>
            <label>{props.label}</label>
            <Select 
                options={props.options} 
                value={field.value || null}
                onChange={onChange}
                onBlur={() => helpers.setTouched(true)}
                placeholder={props.placeholder}
                search={props.search}
                loading={props.loading}
            />
            {meta.touched && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}

export default MySelectInput;