import { observer } from "mobx-react-lite";
import React from "react";
import { Message, Icon, Button} from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";

interface Props {
    id: string;
    name: string;
}

export default observer(function DeleteDepartment(props: Props) {
    const {modalStore, departmentStore} = useStore();
    const {deleteDepartment, loading} = departmentStore;
    const {closeModal} = modalStore;

    const handleDelete = async () => {
        await deleteDepartment(props.id);
        closeModal();
    }
    
    return (
        <>
            <Message icon>
                <Icon name='question' color='red' />
                <Message.Content>
                    <Message.Header style={{marginBottom: 10}}>Abteilung löschen</Message.Header>
                    Wollen Sie wirklich die Abteilung: <strong>{props.name}</strong> löschen?
                </Message.Content>
            </Message>
            
            <Button loading={loading} floated="right"  color="red" content="Ja" onClick={handleDelete} />
            <Button content="Nein" onClick={closeModal}  />
        </>
    )
})