import React from "react";
import { observer } from "mobx-react-lite";
import { Checkbox, Segment, Table } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

export default observer(function PacketsTable() {
    const { packetStore } = useStore();
    const { packets, sortedColumn, sortedDirection, handleSort, toggleEditedState, loadPackets } = packetStore;

    const onToggleEditedState = async (id: string) => {
        await toggleEditedState(id);
        await loadPackets();
    }

    return (
        <Segment>
        <Table compact selectable sortable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell 
                        sorted={sortedColumn === 'edited' ? sortedDirection : undefined}
                        onClick={() => handleSort('edited')}>
                        Bearbeitet
                    </Table.HeaderCell>
                    <Table.HeaderCell 
                        sorted={sortedColumn === 'date' ? sortedDirection : undefined}
                        onClick={() => handleSort('date')}>
                        Datum
                    </Table.HeaderCell>
                    <Table.HeaderCell 
                        sorted={sortedColumn === 'country' ? sortedDirection : undefined}
                        onClick={() => handleSort('country')}>
                        Land
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={sortedColumn === 'zipCode' ? sortedDirection : undefined}
                        onClick={() => handleSort('zipCode')}>
                        Plz
                    </Table.HeaderCell>
                    <Table.HeaderCell 
                        sorted={sortedColumn === 'orderNbr' ? sortedDirection : undefined}
                        onClick={() => handleSort('orderNbr')}>
                        Barcode
                    </Table.HeaderCell>                   
                    <Table.HeaderCell
                        sorted={sortedColumn === 'weight' ? sortedDirection : undefined}
                        onClick={() => handleSort('weight')}>
                        Gewicht (Kg)
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={sortedColumn === 'length' ? sortedDirection : undefined}
                        onClick={() => handleSort('length')}>
                        Länge (cm)
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={sortedColumn === 'width' ? sortedDirection : undefined}
                        onClick={() => handleSort('width')}>
                        Breite (cm)
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={sortedColumn === 'height' ? sortedDirection : undefined}
                        onClick={() => handleSort('height')}>
                        Höhe (cm)
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {packets.map(packet => (
                    <Table.Row key={packet.id}>
                        <Table.Cell>
                            <Checkbox toggle checked={packet.edited} onChange={(e, data) => { onToggleEditedState(packet.id) }} />
                        </Table.Cell> 
                        <Table.Cell>{format(packet.date, 'dd.MM.yyyy HH:mm:ss', {locale: de})}</Table.Cell>                         
                        <Table.Cell>{packet.country}</Table.Cell>  
                        <Table.Cell>{packet.zipCode}</Table.Cell> 
                        <Table.Cell>{packet.orderNbr}</Table.Cell>                          
                        <Table.Cell>{packet.weight}</Table.Cell> 
                        <Table.Cell>{packet.length / 10}</Table.Cell> 
                        <Table.Cell>{packet.width / 10}</Table.Cell> 
                        <Table.Cell>{packet.height / 10}</Table.Cell> 
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    </Segment>
    )
})