import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Grid, Pagination, PaginationProps } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { PagingParams } from "../../../app/models/pagination";
import { useStore } from "../../../app/stores/store";
import DepartmentTable from "./DepartmentTable";

export default observer(function DepartmentDashboard() {
    const { departmentStore } = useStore();
    const { loading, loadDepartments, setPagingParams, pagination } = departmentStore;

    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        loadDepartments();
    }, [loadDepartments])

    const handlePageLoading = (props: PaginationProps) => {
        setPageLoading(true);
        setPagingParams(new PagingParams(+props.activePage!));
        loadDepartments().then(() => setPageLoading(false));
    }

    if(loading && !pageLoading) return <LoadingComponent content='Lade Abteilungen...' />

    return(
        <Grid>
        <Grid.Column width='16'>
            <DepartmentTable />
            <Pagination 
                style={{float: 'right'}}
                disabled={pageLoading}
                defaultActivePage={pagination?.currentPage}
                totalPages={pagination ? pagination.totalPages : 0}
                onPageChange={(e, d) => handlePageLoading(d)}
                boundaryRange={0}
                siblingRange={1}
                ellipsisItem={null}
             />
        </Grid.Column>
    </Grid>
    )
})