import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Container } from 'semantic-ui-react';
import DepartmentDashboard from '../../features/departments/dashboard/DepartmentDashboard';
import DepartmentForm from '../../features/departments/form/DepartmentForm';
import NotFound from '../../features/errors/NotFound';
import ServerError from '../../features/errors/ServerError';
import HomePage from '../../features/home/HomePage';
import PacketsDaschboard from '../../features/packets/PacketsDaschboard';
import ProfileDashboard from '../../features/profile/dashboard/ProfileDashboard';
import RoutingSlipLogRealtimeDashboard from '../../features/routingsliplogs/realtime/RoutingSlipLogRealtimeDashboard';
import RoutingSlipLogDashboard from '../../features/routingsliplogs/RoutingSlipLogDashboard';
import RoutingSlipForm from '../../features/routingslips/RoutingSlipForm';
import UserDaschboard from '../../features/users/dashboard/UserDaschboard';
import CreateUserForm from '../../features/users/form/CreateUserForm';
import EditUserForm from '../../features/users/form/EditUserForm';
import WorkerDashboard from '../../features/workers/dashboard/WorkerDashboard';
import WorkerForm from '../../features/workers/form/WorkerForm';
import ModalContainer from '../common/modals/ModalContainer';
import { useStore } from '../stores/store';
import LoadingComponent from './LoadingComponent';
import NavBar from './NavBar';
import PrivateRoute from './PrivateRoute';

function App() {
  const {commonStore, userStore} = useStore();

  useEffect(() => {
    if(commonStore.token) {
      userStore.getCurrentUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  },[commonStore, userStore]);

  if (!commonStore.appLoaded) return <LoadingComponent content='Lade App...' />
  
  return (
    <>
      <ToastContainer position='bottom-right' hideProgressBar />
      <ModalContainer />
      <Route path='/' exact component={HomePage} />
      <Route 
        path={'/(.+)'}
        render={() => (
          <>
            <NavBar />
            <Container style={{marginTop: '6em'}}>
              <Switch>
                <PrivateRoute path='/routingslip' component={RoutingSlipForm} />
                <PrivateRoute path='/workers' exact component={WorkerDashboard} />
                <PrivateRoute path={['/createWorker','/workers/:id']} component={WorkerForm} />
                <PrivateRoute path='/departments' exact component={DepartmentDashboard} />
                <PrivateRoute path={['/createDepartment','/departments/:id']} component={DepartmentForm} />
                <PrivateRoute path='/routingsliplogs' exact component={RoutingSlipLogDashboard} />
                <PrivateRoute path='/routingsliplogrealtime' exact component={RoutingSlipLogRealtimeDashboard} />
                <PrivateRoute path='/packets' exact component={PacketsDaschboard} />
                <PrivateRoute path='/profile' exact component={ProfileDashboard} />
                <PrivateRoute path='/users' exact component={UserDaschboard} />
                <PrivateRoute path='/users/:id' exact component={EditUserForm} />
                <PrivateRoute path='/createUser' component={CreateUserForm} />
                <Route path='server-error' component={ServerError} />
                <Route component={NotFound} />
              </Switch>
            </Container>
          </>
        )}
      />
    </>
  );
}

export default observer(App);
