import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Icon, Message } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";

interface Props {
    id: string;
    name: string;
}

export default observer(function DeleteUser(props: Props) {
    const {modalStore, userStore} = useStore();
    const {deleteUser, loading} = userStore;
    const {closeModal} = modalStore;

    const handleDelete = async () => {
        await deleteUser(props.id);
        closeModal();
    }

    return (
        <>
            <Message icon>
                <Icon name='question' color='red' />
                <Message.Content>
                    <Message.Header style={{marginBottom: 10}}>Benutzer löschen</Message.Header>
                    Wollen Sie wirklich den Benutzer <strong>{props.name}</strong> löschen?
                </Message.Content>
            </Message>
            
            <Button loading={loading} floated="right"  color="red" content="Ja" onClick={handleDelete} />
            <Button content="Nein" onClick={closeModal}  />
        </>
    )
});