import { observer } from "mobx-react-lite";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Icon, Segment, Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import DeleteUser from "./DeleteUser";

export default observer(function UserTable() {
    const {userStore, modalStore} = useStore();
    const {users} = userStore;
    const history = useHistory();

    const handleEdit = (id: string) => {
        history.push(`/users/${id}`);
    }

    const handleDelete = (id: string, name: string) => {
        modalStore.openModal(<DeleteUser id={id} name={name} />, 'tiny');
    }

    const mapRole = (role: string) => {
        switch(role) {
            case 'Editor':
                return 'Manager';
            case 'User':
                return 'Benutzer';
            default: 
                return role;
        }
    }

    return(
        <Segment>
            <Table compact selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Benutzername</Table.HeaderCell>
                        <Table.HeaderCell>Anzeigename</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Berechtigung</Table.HeaderCell>
                        <Table.HeaderCell>
                            <Button
                                floated="right"
                                icon
                                labelPosition="left"
                                color='blue'
                                size="small"
                                as={Link}
                                to='/createUser'
                            >
                                <Icon name='user' /> Neu
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {users.map(user => (
                        <Table.Row key={user.id}>
                            <Table.Cell>{user.username}</Table.Cell>
                            <Table.Cell>{user.displayName}</Table.Cell>
                            <Table.Cell>{user.email}</Table.Cell>                            
                            <Table.Cell>{mapRole(user.role)}</Table.Cell>   
                            <Table.Cell>
                                { user.username !== 'admin' && (
                                    <div style={{float: 'right'}}>                                        
                                        <Icon onClick={() => handleDelete(user.id, user.displayName)} title="Löschen" name='trash alternate' color="red" style={{marginRight: '20px',cursor: 'pointer'}} />
                                        <Icon onClick={() => handleEdit(user.id)} name='pencil alternate' title="Bearbeiten" style={{cursor: 'pointer'}} />
                                    </div>
                                )}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Segment>
    )
})