import React from "react";
import { observer } from "mobx-react-lite";
import { Segment, Table } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

export default observer(function RoutingSlipLogTable() {
    const { routingSlipLogStore } = useStore();
    const { routingSlipLogs, sortedColumn, sortedDirection, handleSort } = routingSlipLogStore;

    return (
        <Segment>
        <Table compact selectable sortable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell 
                        sorted={sortedColumn === 'date' ? sortedDirection : undefined}
                        onClick={() => handleSort('date')}>
                        Datum
                    </Table.HeaderCell>
                    <Table.HeaderCell 
                        sorted={sortedColumn === 'orderId' ? sortedDirection : undefined}
                        onClick={() => handleSort('orderId')}>
                        Barcode
                    </Table.HeaderCell>
                    <Table.HeaderCell>Nachricht</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {routingSlipLogs.map(log => (
                    <Table.Row key={log.id}>
                        <Table.Cell>{format(log.date, 'dd.MM.yyyy HH:mm:ss', {locale: de})}</Table.Cell>                         
                        <Table.Cell>{log.orderId}</Table.Cell>  
                        <Table.Cell>{log.message}</Table.Cell>  
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    </Segment>
    )
})