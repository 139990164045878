import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import * as Yup from 'yup';
import { Button, Header, Segment } from "semantic-ui-react";
import { Form, Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { roleOptions } from "../../../app/common/options/roles";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { User } from "../../../app/models/user";
import { observer } from "mobx-react-lite";
import FormErrorList from "../../errors/FormErrorList";
import MyRadioToggle from "../../../app/common/form/MyRadioToggle";

export default observer(function UserForm() {
    const {userStore} = useStore();
    const {updateUser, loadUser, loadingInitial, loading} = userStore;
    const { id } = useParams<{id: string}>();
    const history = useHistory();

    const [errors, setErrors] = useState<string[]>([]);
    const [user, setUser] = useState<User>({
        id: '',
        username: '',
        displayName: '',
        holdWorkerNameOnRoutingSlip: false,
        email: '',
        password: '',
        role: 'User'
    });

    useEffect(() => {
       loadUser(id).then(u => setUser({...u!, password: ''}));
    },[id, loadUser]);

    const validationSchema = Yup.object({
        username: Yup.string().required('Der Benutzername ist erforderlich'),
        displayName: Yup.string().required('Der Anzeigename ist erforderlich'),
        email: Yup.string().required('Die Email Adresse ist erforderlich').email('Bitte eine gültige Email Adresse eingeben'),
        role: Yup.string().required('Die Berechtigung ist erforderlich').nullable()
    });

    const handleFormSubmit = (user: User) => {
        updateUser(user)
            .then(() => history.push('/users'))
            .catch(errors => setErrors(errors));
    }

    if(loadingInitial) return <LoadingComponent content="Lade Benutzer..." />

    return (
        <Segment clearing>   
            <Header content='Benutzer bearbeiten' color='teal' />
            
            { errors.length > 0 && <FormErrorList errors={errors} /> }

            <Formik 
                enableReinitialize 
                initialValues={user}
                validationSchema={validationSchema} 
                onSubmit={(values, {resetForm, setValues}) => {
                    handleFormSubmit(values)
                    resetForm();
                    setValues(values);
                }}>
                {({handleSubmit, isValid, isSubmitting, dirty}) => (
                    <Form className='ui form' onSubmit={handleSubmit} autoComplete="off">
                        <MyTextInput name='username' placeholder="Benutzername" />
                        <MyTextInput name='displayName' placeholder="Anzeigename" />
                        <MyTextInput name='email' placeholder="Email Adresse" />
                        <MyTextInput name='password' placeholder="Passwort" type="password" />
                        <MySelectInput name='role' placeholder="Berechtigung" options={roleOptions} />
                        <MyRadioToggle name='holdWorkerNameOnRoutingSlip' label="Benutzerauswahl beim Laufzettel speichern?" />
                        <Button disabled={isSubmitting || !dirty || !isValid} loading={loading} floated="right" positive type='submit' content='Speichern' />
                        <Button as={Link} to='/users' floated="right" type='button' content='Abbrechen' />
                    </Form>
                )}
            </Formik>
        </Segment>
    )    
});