import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { pageCountOptions } from '../options/pageCount';

interface Props {
    onChange: (pageCount: number) => void;
}

export default function PageCount(props: Props) {
    const handlePageCountChanged = (data: any) => {
        props.onChange(data.value);
    }

    return (
        <>
            <Dropdown
                selection
                compact
                options={pageCountOptions}
                defaultValue={pageCountOptions[0].value}
                onChange={(_, data) => handlePageCountChanged(data)}
            />
            &nbsp;
            Zeilen pro Seite
        </>
    );
}