import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Grid, Pagination, PaginationProps } from "semantic-ui-react";
import PageCount from "../../app/common/components/PageCount";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import RoutingSlipLogSearchBars from "./RoutingSlipLogSearchBar";
import RoutingSlipLogTable from "./RoutingSlipLogTable";
import FileSaver from "file-saver";

export default observer(function RoutingSlipLogDashboard() {
    const { routingSlipLogStore, workerStore, departmentStore } = useStore();
    const { loadOptions: loadWorkerOptions } = workerStore;
    const { loadOptions } = departmentStore;
    const { loadRoutingSlipLogs, loading, pagination, setPageNumber, setPageSize, csvFile, setCsvFile, loadCsvFile } = routingSlipLogStore;

    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        loadWorkerOptions(false);
        loadOptions();
        loadRoutingSlipLogs();
    }, [loadRoutingSlipLogs, loadWorkerOptions, loadOptions])

    useEffect(() => {
        if(csvFile !== null) {

            FileSaver.saveAs(csvFile!, "logs.csv");
            setCsvFile(null);
        }
    }, [csvFile, setCsvFile]);

    const handlePageLoading = (props: PaginationProps) => {
        setPageLoading(true);
        setPageNumber(+props.activePage!);
        loadRoutingSlipLogs().then(() => setPageLoading(false));
    }

    const handlePageCountChanged = (pageSize: number) => {
        setPageLoading(true);
        setPageNumber(1);
        setPageSize(pageSize);
        loadRoutingSlipLogs().then(() => setPageLoading(false));
    }

    const handleDownloadCSV = () => {
        setPageLoading(true);
        loadCsvFile().then(() => setPageLoading(false));
    }

    return(
        <Grid>
            <Grid.Row>
                <Grid.Column width={13}>
                    <RoutingSlipLogSearchBars />
                </Grid.Column>
                <Grid.Column width={3}>
                    <Button onClick={handleDownloadCSV} style={{float: 'right'}} primary>Download CSV</Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>     
                    { (loading) && <LoadingComponent content='Lade Logs...' /> }         
                    <RoutingSlipLogTable />
                    <PageCount onChange={handlePageCountChanged} />
                    <Pagination 
                        style={{float: 'right'}}
                        disabled={pageLoading}
                        activePage={pagination?.currentPage}
                        defaultActivePage={pagination?.currentPage}
                        totalPages={pagination ? pagination.totalPages : 0}
                        onPageChange={(e, d) => handlePageLoading(d)}
                        boundaryRange={0}
                        siblingRange={1}
                        ellipsisItem={null}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
})