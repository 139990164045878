import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { RoutingSlipEvent } from "../models/routingSlip";

interface WorkerOptions {
    key: string;
    value: string;
    text: string;
}

export class RoutingSlipStore {
    workerOptions: WorkerOptions[] = [];
    initialLoading = false;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    loadOptions = async (onlySignUpUsers: boolean) => {
        this.initialLoading = true;
        try {
            const params = new URLSearchParams();
            params.append('onlySignUpUsers', JSON.stringify(onlySignUpUsers));
            params.append('activeUsers', "true");

            const workers = await agent.Workers.dropdown(params);
            runInAction(() => {
                this.workerOptions = [];
                workers.forEach(worker => {
                    this.workerOptions.push({
                        key: worker.id,
                        value: worker.id,
                        text: worker.name
                    });
                });
                this.initialLoading = false
            });
        } catch(error) {
            console.log(error);
            runInAction(() => this.initialLoading = false);
        }
    }

    sendEvent = async (event: RoutingSlipEvent) => {
        this.loading = true;
        try {
            await agent.RoutingSlips.create(event);
            runInAction(() => this.loading = false);
        } catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
            throw error;
        }
    }
}