import { Form, Formik } from "formik";
import React from "react";
import { Button, Header, Segment } from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import * as Yup from 'yup';
import { useStore } from "../../../app/stores/store";
import { toast } from "react-toastify";

export default function ChangePasswordForm() {
    const { userStore } = useStore();

    const validationSchema = Yup.object({
        currentPassword: Yup.string().required('Das aktuelle Passwort ist erforderlich'),
        newPassword: Yup.string().required('Das neue Passwort ist erforderlich'),
    });

    return (
        <Segment clearing>   
            <Header content='Passwort ändern' color='teal' />
            <Formik 
                initialValues={{currentPassword: '', newPassword: '', error: null}}
                validationSchema={validationSchema} 
                onSubmit={async (values, {resetForm, setFieldError}) => {
                    try {
                        await userStore.changePassword(values);
                        toast.success('Passwort geändert');
                        resetForm();
                    }
                    catch(errors: any) {
                        if(Array.isArray(errors)) {
                            setFieldError('newPassword', errors[0]);
                        }
                    }
                }}>
                {({handleSubmit, isValid, isSubmitting, dirty}) => (
                    <Form className='ui form' onSubmit={handleSubmit} autoComplete="off">
                        <MyTextInput name='currentPassword' placeholder="aktuelles Passwort" type="password" />
                        <MyTextInput name='newPassword' placeholder="neues Passwort" type="password" />  
                        <Button disabled={isSubmitting || !dirty || !isValid} loading={isSubmitting} floated="right" positive type='submit' content='Passwort ändern' />
                    </Form>
                )}
            </Formik>
            
        </Segment>
    )
}