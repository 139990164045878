import React from "react";
import { Form } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";

export default function PaketsSearchBar() {
    const { packetStore } = useStore();
    const { loadPackets, setSearchField, setAllPacketField } = packetStore

    const onFormSubmit = () => {
        loadPackets();
    }

    return (
            <Form autoComplete="off" onSubmit={onFormSubmit}>
                <Form.Group widths="equal">
                    <Form.Checkbox 
                        label="Auch bearbeitet anzeigen"
                        onChange={(e, data) => setAllPacketField(data.checked!)}
                        toggle                         
                    />

                    <Form.Input 
                        placeholder="Auftragsnummer"
                        onChange={(e) => setSearchField(e.target.value)}                        
                    />
                        
                    <Form.Button 
                        type='submit' 
                        primary
                        content="OK"
                    />                        
                </Form.Group>
            </Form>
    );
}