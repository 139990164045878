import React from "react";
import RoutingSlipRealtimeTable from "./RoutingSlipRealtimeTable";

export default function RoutingSlipLogRealtimeDashboard() {
    
    return (
        <>
            <h3>Echtzeitlog</h3>
            <RoutingSlipRealtimeTable />
        </>
    );
}