import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Pagination } from '../models/pagination';
import { Packet } from '../models/packet';

export class PacketStore {
    packetRegistry = new Map<string, Packet>();
    csvFile: Blob | null = null;
    pagination: Pagination | null = null;
    pageNumber: number = 1;
    pageSize: number = 15;
    sortedColumn: string | null = null;
    sortedDirection: "ascending" | "descending" | undefined;
    loading = false;
    searchField: string | null = null;
    allPacketsField: boolean | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pageNumber.toString());
        params.append('pageSize', this.pageSize.toString());
        return params;
    }

    get packets() {
        return Array.from(this.packetRegistry.values());
    }

    handleSort = async (column: string) => {
        if(column === this.sortedColumn) {
            this.sortedDirection = this.sortedDirection === 'ascending' ? 'descending' : 'ascending';
        }
        else {
            this.sortedDirection = 'ascending';
        }
        this.sortedColumn = column;

        await this.loadPackets();
    }

    loadCsvFile = async () =>  {
        this.loading = true;
        try {
            const response = await agent.Packets.csv();
            
            runInAction(() => {
                this.setCsvFile(response);
                this.loading = false;
            })
        }
        catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
        }
    }

    toggleEditedState = async (id: string) => {
        this.loading = true;
        try {                   
            const packet = this.packetRegistry.get(id);
            if(packet == null) return;

            packet.edited = !packet.edited;
            await agent.Packets.update(packet);
        }
        catch(error) {
            console.log(error);
        }
        finally {
            runInAction(() => this.loading = false);
        }
    }

    loadPackets = async () => {
        this.loading = true;
        try {
            const params = this.axiosParams;

            if(this.searchField) {
                params.append("searchBy", this.searchField);
            }
            if(this.sortedColumn) {
                params.append("sortBy", this.sortedColumn);
            }
            if(this.sortedDirection) {
                params.append("sortOrder", this.sortedDirection);
            }
            if(this.allPacketsField) {
                params.append("showAllPakets", "true");
            }

            const result = await agent.Packets.list(params);

            runInAction(() => {
                this.packetRegistry.clear();
                result.data.forEach(packet => {
                    packet.date = new Date(packet.date);
                    this.packetRegistry.set(packet.id, packet);
                })
                this.setPagination(result.pagination);
                this.loading = false;
            })
        }
        catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
        }
    }

    setCsvFile = (data: Blob | null) => {
        this.csvFile = data;
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setSearchField = (searchField: string) => {
        this.searchField = searchField;
    }

    setAllPacketField = (allPacketsField: boolean) => {
        this.allPacketsField = allPacketsField;
    }

    setPageNumber = (pageNumber: number) => {
        this.pageNumber = pageNumber;
    }

    setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    }
}
