import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Icon, Segment, Table } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/store';
import DeleteDepartment from './DeleteDepartment';

export default function DepartmentTable() {
    const { departmentStore, modalStore } = useStore();
    const { departments } = departmentStore;
    const history = useHistory();

    const handleEdit = (id: string) => {
        history.push(`/departments/${id}`);
    }

    const handleDelete = (id: string, name: string) => {
        modalStore.openModal(<DeleteDepartment id={id} name={name} />, 'tiny');
    }

    return (
        <Segment>
        <Table compact selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Bezeichnung</Table.HeaderCell>
                    <Table.HeaderCell>
                        <Button
                            floated="right"
                            icon
                            labelPosition="left"
                            color='blue'
                            size="small"
                            as={Link}
                            to='/createDepartment'
                        >
                            <Icon name='group' /> Neu
                        </Button>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {departments.map(dep => (
                    <Table.Row key={dep.id}>
                        <Table.Cell>{dep.name}</Table.Cell>                         
                        <Table.Cell collapsing>
                            <div style={{float: 'right'}}>
                                <Icon onClick={() => handleDelete(dep.id, dep.name)} title="Löschen" name='trash alternate' color="red" style={{marginRight: '20px',cursor: 'pointer'}} />
                                <Icon onClick={() => handleEdit(dep.id)} title="Bearbeiten" name='pencil alternate' style={{cursor: 'pointer'}} />
                            </div>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    </Segment>
    )
}