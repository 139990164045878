import { observer } from "mobx-react-lite";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Container, Menu, Image, Dropdown } from "semantic-ui-react";
import { useStore } from "../stores/store";

export default observer(function NavBar() {
    const {userStore: { user, logout }} = useStore();
    return (
        <Menu inverted fixed='top'>
            <Container>
                <Menu.Item as={NavLink} to='/' exact header>
                    Produktions App
                </Menu.Item>
                <Menu.Item as={NavLink} to='/routingslip' name='Laufzettel' />
                { (user?.role === 'Admin' || user?.role === 'Editor') && <Menu.Item as={NavLink} to='/workers' name='Mitarbeiter' /> }
                { (user?.role === 'Admin' || user?.role === 'Editor') && <Menu.Item as={NavLink} to='/departments' name='Abteilungen' /> }
                {  user?.role === 'Admin' && <Menu.Item as={NavLink} to='/users' name='Benutzer' /> }
                { (user?.role === 'Admin' || user?.role === 'Editor') && 
                    <Dropdown item text='Log'>
                        <Dropdown.Menu>
                            <Dropdown.Item as={NavLink} to='/routingsliplogs' text='History' />
                            <Dropdown.Item as={NavLink} to='/routingsliplogrealtime' text='Echtzeit' />
                            <Dropdown.Item as={NavLink} to='/packets' text='Pakete' />
                        </Dropdown.Menu>
                    </Dropdown>
                }              
                <Menu.Item position='right'>
                    <Image src='/assets/user.png' avatar spaced='right' />
                    <Dropdown pointing='top left' text={user?.displayName}>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to={`/profile`} text='Mein Profil' icon='user' />
                            <Dropdown.Item onClick={logout} text='Abmelden' icon='power' />
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Item>
            </Container>
        </Menu>
    )
})