import { makeAutoObservable, runInAction } from "mobx";
import { RoutingSlipLog } from "../models/routingSlipLog";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr"
import { store } from "./store";

export default class RoutingSlipRealtimeStore {
    routingSlips: RoutingSlipLog[] = [];
    hubConnection: HubConnection | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    createHubConnection = () => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_LOG_HUB_URL || "", {
                accessTokenFactory: () => store.userStore.user?.token!
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        this.hubConnection.start().catch(error => console.log('Error establishing the connection: ', error));

        this.hubConnection.on('LoadLogEntries', (logs: RoutingSlipLog[]) => {
            runInAction(() => {
                logs.forEach(log => {
                    log.date = new Date(log.date);
                })
                this.routingSlips = logs;
            });
        });

        this.hubConnection.on('ReceiveLogEntry', (log: RoutingSlipLog) => {
            runInAction(() => {
                log.date = new Date(log.date);
                this.routingSlips.unshift(log); 
            })            
        });
    }

    stopHubConnection = () => {
        this.hubConnection?.stop().catch(error => console.log('Error stopping connection: ', error));               
    }

    clearLogs = () => {
        this.routingSlips = [];
        this.stopHubConnection();
    }
}