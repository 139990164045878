import { makeAutoObservable } from "mobx";

interface Modal {
    size: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
    open: boolean;
    body: JSX.Element | null;
}

export default class ModalStore {
    modal: Modal = {
        size: 'mini',
        open: false,

        body: null,
    }

    constructor() {
        makeAutoObservable(this);
    }

    openModal = (content: JSX.Element, size: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen' = 'mini') => {
        this.modal.open = true;
        this.modal.body = content;
        this.modal.size = size;
    }

    closeModal = () => {
        this.modal.open = false;
        this.modal.body = null;
    }
}