import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Worker, WorkerList } from "../models/worker";
import { v4 as uuid } from 'uuid';
import { Pagination, PagingParams } from "../models/pagination";

interface WorkerOptions {
    key: string;
    value: string;
    text: string;
}

export default class WorkerStore {
    workerRegistry = new Map<string, WorkerList>();
    workerOptions: WorkerOptions[] = [];
    pagination: Pagination | null = null;
    selectedWorker: Worker | null = null;
    pagingParams = new PagingParams();
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    get workers() {
        return Array.from(this.workerRegistry.values());
    }

    loadWorkers = async (active?: boolean) => {
        this.loadingInitial = true;
        try {
            const result = await agent.Workers.list(this.axiosParams);
            
            runInAction(() => {
                this.workerRegistry.clear();                
                result.data.forEach(worker => {
                    this.workerRegistry.set(worker.id, worker);
                });
                this.setPagination(result.pagination);
                this.loadingInitial = false;
            });
        } catch(error) {
            console.log(error);
            runInAction(() => this.loadingInitial = false);
        }
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    loadWorker = async (id: string) => {
        this.loadingInitial = true;
        try {
            const worker = await agent.Workers.details(id);
            runInAction(() => {
                this.loadingInitial = false;
            })
            return worker;
        } catch(error) {
            console.log(error);
            runInAction(() => this.loadingInitial = false);
        }
    }

    createWorker = async (worker: Worker) => {
        this.loading = true;
        worker.id = uuid();
        try {
            await agent.Workers.create(worker);
            runInAction(() => this.loading = false);
        } catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
        }
    }

    updateWorker = async (worker: Worker) => {
        this.loading = true;
        try {
            await agent.Workers.update(worker);
            runInAction(() => this.loading = false);
        } catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
        }
    }

    deleteWorker = async (id: string) => {
        this.loading = true;
        try {
            await agent.Workers.delete(id);
            runInAction(() => {
                this.workerRegistry.delete(id);
                this.loading = false;
            })
        } catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
        }
    }

    loadOptions = async (onlySignUpUsers: boolean) => {
        this.loading = true;
        try {
            const params = new URLSearchParams();
            params.append('onlySignUpUsers', JSON.stringify(onlySignUpUsers));

            const workers = await agent.Workers.dropdown(params);
            runInAction(() => {
                this.workerOptions = [];
                workers.forEach(worker => {
                    this.workerOptions.push({
                        key: worker.id,
                        value: worker.id,
                        text: worker.name
                    });
                });
                this.loading = false
            });
        } catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
        }
    }
}