import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, Header, Icon, Segment } from "semantic-ui-react";
import * as Yup from 'yup';
import { useStore } from "../../app/stores/store";
import MyTextInput from "../../app/common/form/MyTextInput";
import { observer } from "mobx-react-lite";
import MySelectInput from "../../app/common/form/MySelectInput";
import FormErrorList from "../errors/FormErrorList";
import { toast } from "react-toastify";

export default observer(function RoutingSlipForm() {
    const { routingSlipStore, userStore } = useStore();
    const { loadOptions, initialLoading, loading, sendEvent } = routingSlipStore;
    const { user } = userStore;

    const inputRef = useRef<HTMLInputElement>();

    const [errors, setErrors] = useState<string[]>([]);

    const initialValues = {
        selectedWorker: null,
        message: '',
        barcode: ''
    }

    useEffect(() => {
        loadOptions(true);
    },[loadOptions])

    const validationSchema = Yup.object({
        selectedWorker: Yup.string().required('Bitte einen Mitarbeiter auswählen').nullable(),
        message: Yup.string().max(20, 'Die Nachricht darf max. 20 Zeichen lang sein.'),
        barcode: Yup.string().required('Der Barcode ist erforderlich')
    })

    return (
        <Segment clearing>
            <Header content="Laufzettel bearbeiten" color='teal' />

            { errors.length > 0 && <FormErrorList errors={errors} /> }

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, {resetForm, setValues}) => {
                    sendEvent({ workerId: values.selectedWorker!, barcode: values.barcode, message: values.message })
                        .then(() => {

                            setErrors([]);
                            toast.success('Laufzettel gescannt');
                            resetForm();  

                            if(user?.holdWorkerNameOnRoutingSlip) {
                                setValues({...values, barcode: ''});
                                inputRef.current?.focus();
                            }       
                        })
                        .catch(errors => {
                            setErrors(errors);
                            resetForm();
                            setValues({...values, barcode: ''});
                            inputRef.current?.focus();
                            console.log(errors);
                        });
                }}
                >
                {({handleSubmit, isValid, isSubmitting, dirty}) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='off'>
                        <Grid textAlign="center" style={{marginBottom: 10}}>
                            <Grid.Column width={8}>
                                <Segment placeholder loading={initialLoading}>
                                    <Header icon>
                                        <Icon name='user' />
                                        1. Mitarbeiter auswählen
                                    </Header>
                                    <Segment.Inline>
                                        <MySelectInput search name='selectedWorker' placeholder="Mitarbeiter" options={routingSlipStore.workerOptions} /> 
                                        { user?.holdWorkerNameOnRoutingSlip && <MyTextInput name='message' placeholder="Extra Nachricht" /> }
                                    </Segment.Inline>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Segment placeholder>
                                    <Header icon>
                                        <Icon name="barcode" />
                                        2. Laufzettel scannen
                                    </Header>
                                    <MyTextInput name='barcode' placeholder="Barcode" ref={inputRef} />
                                </Segment>
                            </Grid.Column>                        
                        </Grid>
                        <Button floated="right" disabled={isSubmitting || !dirty || !isValid} loading={loading}  positive type="submit" content="3. Senden" />
                    </Form>
                )}
            </Formik>
        </Segment>
    )
})