import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Department } from "../models/department";
import { Pagination, PagingParams } from "../models/pagination";
import { v4 as uuid } from 'uuid';

interface DepartmentOptions {
    key: string;
    value: string;
    text: string;
}

export class DepartmentStore {
    departmentRegistry = new Map<string, Department>();
    departmentOptions: DepartmentOptions[] = [];
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();
    loading= false;

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        return params;
    }

    get departments() {
        return Array.from(this.departmentRegistry.values());
    }

    loadDepartments = async () => {
        this.loading = true;
        try {
            const result = await agent.Departments.list(this.axiosParams);

            runInAction(() => {
                this.departmentRegistry.clear();
                result.data.forEach(dep => {
                    this.departmentRegistry.set(dep.id, dep);
                });
                this.setPagination(result.pagination);
                this.loading = false;
            })
        }
        catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
        }
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    loadDepartment = async (id: string) => {
        this.loading = true;
        try {
            const department = await agent.Departments.details(id);
            runInAction(() => {
                this.loading = false;
            })
            return department;
        }
        catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
        }
    }

    createDepartment = async(department: Department) => {
        this.loading = true;
        department.id = uuid();
        try {
            await agent.Departments.create(department);
        }
        catch(error) {
            console.log(error);
        }
        finally{
            runInAction(() => this.loading = false);
        }
    }

    updateDepartment = async(department: Department) => {
        this.loading = true;
        try {
            await agent.Departments.update(department);
        }   
        catch(error) {
            console.log(error);
        }
        finally{
            runInAction(() => this.loading = false);
        }
    }

    deleteDepartment = async (id: string) => {
        this.loading = true;
        try {
            await agent.Departments.delete(id);
            runInAction(() => {
                this.departmentRegistry.delete(id);
            });
        }
        catch(error) {
            console.log(error);            
        }
        finally{
            runInAction(() => this.loading = false);
        }
    }

    loadOptions = async() => {
        this.loading = true;
        try {
            const params = new URLSearchParams();
            params.append('pageNumber', '1');
            params.append('pageSize', '1000');

            const departments = await agent.Departments.list(params);
            runInAction(() => {
                this.departmentOptions = [];
                departments.data.forEach(department => {
                    this.departmentOptions.push({
                        key: department.id,
                        value: department.id,
                        text: department.name
                    });
                });
                this.loading = false;
            })
        }
        catch(error) {
            console.log(error);
            runInAction(() => this.loading = false);
        }
    }
}