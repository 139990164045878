import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { history } from "../..";
import { Department } from "../models/department";
import { RoutingSlipLog } from "../models/routingSlipLog";
import { PaginatedResult } from "../models/pagination";
import { RoutingSlipEvent } from "../models/routingSlip";
import { CurrentUser, LoginUser, User, UserChangePassword, UserRole } from "../models/user";
import { Worker, WorkerList } from "../models/worker";
import { store } from "../stores/store";
import { Packet } from "../models/packet";

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    })
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if(token) config.headers!.Authorization = `Bearer ${token}`;

    return config;
})

axios.interceptors.response.use(async response => {
    if(process.env.NODE_ENV === 'development') await sleep(1000);

    const pagination = response.headers['pagination'];
    if(pagination) {
        response.data = new PaginatedResult(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>
    }
    return response;
}, (error: AxiosError) => {
    const {data, status, config, headers} = error.response!;
    switch(status) {
        case 400:
            if(typeof data === 'string') {
                toast.error(data);
            }
            if(config.method === 'get' && data.errors.hasOwnProprty('id')) {
                history.push('/not-found');
            }
            if(data.errors) {
                const modelStateError = [];
                for(const key in data.errors) {
                    if(data.errors[key]) {
                        modelStateError.push(data.errors[key]);
                    }
                }
                throw modelStateError.flat();
            }
            break;
        case 401:
            if (headers['www-authenticate'].startsWith('Bearer error="inavlid_token"')) {
                store.userStore.logout();
                toast.error('Sitzung abgelaufen. Bitte neu anmelden');
            }            
            break;
        case 404:
            history.push('/not-found');
            break;
        case 500:
            store.commonStore.setServerError(data);
            history.push('/server-error');
            break;
    }
    return Promise.reject(error);
})


const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    delete: <T> (url: string) => axios.delete<T>(url).then(responseBody)
}

const Workers = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<WorkerList[]>>('/workers', {params}).then(responseBody),
    dropdown: (params: URLSearchParams) => axios.get<WorkerList[]>('/workers/dropdown', {params}).then(responseBody),
    details: (id: string) => requests.get<Worker>(`/workers/${id}`),
    create: (worker: Worker) => requests.post<void>('/workers', worker),
    update: (worker: Worker) => requests.put<void>(`/workers/${worker.id}`, worker),
    delete: (id: string) => requests.delete<void>(`/workers/${id}`)
}

const Departments = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Department[]>>('/departments', {params}).then(responseBody),
    details: (id: string) => requests.get<Department>(`/departments/${id}`),
    create: (department: Department) => requests.post<void>('/departments', department),
    update: (department: Department) => requests.put<void>(`/departments/${department.id}`, department),
    delete: (id: string) => requests.delete<void>(`/departments/${id}`)
}

const RoutingSlipLogs = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<RoutingSlipLog[]>>('/routingsliplogs', {params}).then(responseBody),
    csv: (params: URLSearchParams) => axios.get<Blob>('routingsliplogs/csv', {params, responseType: 'blob'}).then(responseBody)
}

const Packets = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Packet[]>>('/packageproperties', {params}).then(responseBody),
    update: (packet: Packet) => requests.put<void>(`/packageproperties/${packet.id}`, packet),
    csv: () => axios.get<Blob>('/packageproperties/csv', {responseType: 'blob'}).then(responseBody)
}

const Account = {
    login: (user: LoginUser) => requests.post<CurrentUser>('/account/login', user),
    current: () => requests.get<CurrentUser>('/account/current'),
    changePassword: (changePassword: UserChangePassword) => requests.put<void>('/account/password', changePassword),
    refreshToken: () => requests.post<CurrentUser>('/account/refreshToken', {})
}

const Users = {
    list: () => requests.get<User[]>('/users'),
    details: (id: string) => requests.get<User>(`/users/${id}`),
    create: (user: User) => requests.post<void>('/users', user),
    update: (user: User) => requests.put<void>(`/users/${user.id}`, user),
    delete: (id: string) => requests.delete<void>(`/users/${id}`)
}

const Roles = {
    list: () => requests.get<UserRole[]>('/roles')
}

const RoutingSlips = {
    create:(routingSlipEvent: RoutingSlipEvent) => requests.post<void>('/routingSlip', routingSlipEvent)
}

const agent = {
    Workers,
    Departments,
    RoutingSlipLogs,
    Account,
    Users,
    Roles,
    RoutingSlips,
    Packets
}

export default agent;